import URLSearchParams from './classes/URLSearchParams.js'
import FormData from './classes/FormData.js'

/**
 * Determine if we're running in a standard browser environment
 *
 * This allows axios to run in a web worker, and react-native.
 * Both environments support XMLHttpRequest, but not fully standard globals.
 *
 * web workers:
 * typeof window -> undefined
 * typeof document -> undefined
 *
 * react-native:
 * navigator.product -> 'ReactNative'
 * nativescript
 * navigator.product -> 'NativeScript' or 'NS'
 *
 * @returns {boolean}
 */
const isStandardBrowserEnv = (() => {
 let product;
 if (typeof navigator !== 'undefined' && (
 (product = navigator.product) === 'ReactNative' ||
 product === 'NativeScript' ||
 product === 'NS')
 ) {
 return false;
 }

 return typeof window !== 'undefined' && typeof document !== 'undefined';
})();

/**
 * Determine if we're running in a standard browser webWorker environment
 *
 * Although the `isStandardBrowserEnv` method indicates that
 * `allows axios to run in a web worker`, the WebWorker will still be
 * filtered out due to its judgment standard
 * `typeof window !== 'undefined' && typeof document !== 'undefined'`.
 * This leads to a problem when axios post `FormData` in webWorker
 */
 const isStandardBrowserWebWorkerEnv = (() => {
 return (
 typeof WorkerGlobalScope !== 'undefined' &&
 // eslint-disable-next-line no-undef
 self instanceof WorkerGlobalScope &&
 typeof self.importScripts === 'function'
 );
})();


export default {
 isBrowser: true,
 classes: {
 URLSearchParams,
 FormData,
 Blob
 },
 isStandardBrowserEnv,
 isStandardBrowserWebWorkerEnv,
 protocols: ['http', 'https', 'file', 'blob', 'url', 'data']
};
