'use strict';

import utils from '../utils.js';
import AxiosHeaders from "./AxiosHeaders.js";

const headersToObject = (thing) => thing instanceof AxiosHeaders ? thing.toJSON() : thing;

/**
 * Config-specific merge-function which creates a new config-object
 * by merging two configuration objects together.
 *
 * @param {Object} config1
 * @param {Object} config2
 *
 * @returns {Object} New object resulting from merging config2 to config1
 */
export default function mergeConfig(config1, config2) {
 // eslint-disable-next-line no-param-reassign
 config2 = config2 || {};
 const config = {};

 function getMergedValue(target, source, caseless) {
 if (utils.isPlainObject(target) && utils.isPlainObject(source)) {
 return utils.merge.call({caseless}, target, source);
 } else if (utils.isPlainObject(source)) {
 return utils.merge({}, source);
 } else if (utils.isArray(source)) {
 return source.slice();
 }
 return source;
 }

 // eslint-disable-next-line consistent-return
 function mergeDeepProperties(a, b, caseless) {
 if (!utils.isUndefined(b)) {
 return getMergedValue(a, b, caseless);
 } else if (!utils.isUndefined(a)) {
 return getMergedValue(undefined, a, caseless);
 }
 }

 // eslint-disable-next-line consistent-return
 function valueFromConfig2(a, b) {
 if (!utils.isUndefined(b)) {
 return getMergedValue(undefined, b);
 }
 }

 // eslint-disable-next-line consistent-return
 function defaultToConfig2(a, b) {
 if (!utils.isUndefined(b)) {
 return getMergedValue(undefined, b);
 } else if (!utils.isUndefined(a)) {
 return getMergedValue(undefined, a);
 }
 }

 // eslint-disable-next-line consistent-return
 function mergeDirectKeys(a, b, prop) {
 if (prop in config2) {
 return getMergedValue(a, b);
 } else if (prop in config1) {
 return getMergedValue(undefined, a);
 }
 }

 const mergeMap = {
 url: valueFromConfig2,
 method: valueFromConfig2,
 data: valueFromConfig2,
 baseURL: defaultToConfig2,
 transformRequest: defaultToConfig2,
 transformResponse: defaultToConfig2,
 paramsSerializer: defaultToConfig2,
 timeout: defaultToConfig2,
 timeoutMessage: defaultToConfig2,
 withCredentials: defaultToConfig2,
 adapter: defaultToConfig2,
 responseType: defaultToConfig2,
 xsrfCookieName: defaultToConfig2,
 xsrfHeaderName: defaultToConfig2,
 onUploadProgress: defaultToConfig2,
 onDownloadProgress: defaultToConfig2,
 decompress: defaultToConfig2,
 maxContentLength: defaultToConfig2,
 maxBodyLength: defaultToConfig2,
 beforeRedirect: defaultToConfig2,
 transport: defaultToConfig2,
 httpAgent: defaultToConfig2,
 httpsAgent: defaultToConfig2,
 cancelToken: defaultToConfig2,
 socketPath: defaultToConfig2,
 responseEncoding: defaultToConfig2,
 validateStatus: mergeDirectKeys,
 headers: (a, b) => mergeDeepProperties(headersToObject(a), headersToObject(b), true)
 };

 utils.forEach(Object.keys(config1).concat(Object.keys(config2)), function computeConfigValue(prop) {
 const merge = mergeMap[prop] || mergeDeepProperties;
 const configValue = merge(config1[prop], config2[prop], prop);
 (utils.isUndefined(configValue) && merge !== mergeDirectKeys) || (config[prop] = configValue);
 });

 return config;
}
